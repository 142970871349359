import { ConvertType, convertCaseList } from '@/shared/utils/convertString'
import { IProfile, IProfileResponse, IProfileUpdate, ITermsOfServiceUpdateParams } from './types'
import axiosInstance from '@/shared/utils/axios'

export const requestProfile = async () => {
  const resp = await axiosInstance.get<IProfileResponse>(`${window.apiEndpoint}/me`)
  return convertCaseList(resp.data, ConvertType.CAMEL) as IProfile
}

export const requestRole = async () => {
  const resp = await axiosInstance.get(`${window.apiEndpoint}/me/roles`)
  return convertCaseList(resp.data.roles, ConvertType.CAMEL) as string[]
}

export const requestUpdateProfile = async (data: IProfileUpdate) => {
  const resp = await axiosInstance.post(
    `${window.apiEndpoint}/me/profiles`,
    convertCaseList(data, ConvertType.SNAKE),
  )

  return resp.data
}

export const requestCheckPassword = async (password: string) => {
  const resp = await axiosInstance.post(`${window.apiEndpoint}/me/check-password`, {
    password,
  })

  return resp.data
}

export const requestChangePassword = async (password: string) => {
  const resp = await axiosInstance.post(`${window.apiEndpoint}/me/change-password`, {
    password,
  })

  return resp.data
}

export const requestFetchTermsOfService = async () => {
  const resp = await axiosInstance.get(`${window.apiEndpoint}/me/terms-of-service`)

  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const requestUpdateTermsOfService = async (data: ITermsOfServiceUpdateParams) => {
  const resp = await axiosInstance.patch(
    `${window.apiEndpoint}/me/terms-of-service`,
    convertCaseList(data, ConvertType.SNAKE),
  )

  return resp.data
}
